<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import store from "@/state/store";
import Multiselect from "vue-multiselect";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    const permissions = store.getters["auth/permissions"];
    if (
      (userType === 1 || userType === 3) &&
      permissions.includes("HR_CONFIG")
    ) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Performance Improvement",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.refreshTable();
    this.getEmployees();
  },
  validations: {
    chosen_employees: { required },
    start_date: { required },
    end_date: { required },
  },
  data() {
    return {
      submitting: false,
      passed: false,
      performances: [],
      //employees: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "holiday_id",
      sortDesc: false,
      submitted: false,
      title: "Performance Improvement",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Performance Improvement",
          active: true,
        },
      ],
      employees:[],
      chosen_employees:[],
      start_date: null,
      end_date: null,
      max_end_date:null,
      performanceId:null,
      selectedEmployee:null,
      performanceStatus:1,
      fields: [
        { key: "sn", label: "S/No", sortable: true },
        { key: "employee", label: "Employee", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "start_date", label: "Start Date", sortable: true },
        { key: "end_date", label: "End Date", sortable: true },
      ],
      currentPh: null,
      confirmDelete: false,
      confirmPHDelete: false,
    };
  },
  methods: {
    getEmployees() {
      const url = `${this.ROUTES.employee}`;
      this.apiGet(url, "Get Employees Error").then((res) => {
        const { data } = res;
        data.map(emp=>{
          this.employees.push({
            value: emp.emp_id,
            text: `${emp.emp_first_name} ${emp.emp_last_name} (${emp.emp_unique_id})`,
          })
        });
      });
    },
    setStartDate(){
      const startDate = new Date(this.start_date);// new Date();
      const numberOfDaysToAdd = 90; //3 months
      this.max_end_date =  startDate.setDate(startDate.getDate() + numberOfDaysToAdd );
    },

    setEndDate(){
      const maxEndDate = new Date(this.max_end_date);
      const endDate = new Date(this.end_date);
      if( endDate.getTime()  >  maxEndDate.getTime() ){
        alert('End date is beyond the allocated period of 3 months');
      }
    },
    refreshTable() {
      this.apiGet(
        this.ROUTES.performanceImprovement,
        "Error getting performance improvement"
      ).then((res) => {
        const { performance } = res.data;
        this.performance = [];

        performance.map((perform, index) => {
          this.performances.push({
            sn: index + 1,
            start_date: new Date(perform.pi_start_date).toDateString(),
            end_date: new Date(perform.pi_end_date).toDateString(),
            employee: `${perform.employee.emp_first_name} ${perform?.employee.emp_last_name} (${perform?.employee.emp_unique_id})`,
            status: `${ perform.pi_status === 1 ? 'Active' : 'Inactive'}`,
            empId:perform.pi_emp_id,
            id:perform.pi_id,
          });
        });

        this.totalRows = this.performances.length;
      });
    },
    employeeAsLabel({ text }) {
      return `${text}`;
    },
    resetForm() {
      this.public_name = null;
      this.public_date = null;
      this.public_date = null;
      this.public_date_to = null;
      this.$v.$reset();
    },
    selectPerformance(perform) {
      perform = perform[0];
      //console.log(perform)

      const startDate = new Date(this.start_date);// new Date();
      const numberOfDaysToAdd = 90; //3 months
      this.max_end_date =  startDate.setDate(startDate.getDate() + numberOfDaysToAdd );


      this.performanceId = perform.id;
      this.empId = perform.empId;

      let start = new Date(perform.start_date);
      let end = new Date(perform.end_date);
      this.start_date = new Date(parseInt(start.getUTCFullYear()), parseInt(start.getUTCMonth() ), parseInt( start.getDate() + 1 ))
        .toISOString().split("T")[0];
      this.end_date = new Date(parseInt(end.getUTCFullYear()), parseInt(end.getUTCMonth()), parseInt(end.getDate() + 1))
        .toISOString().split("T")[0];

      this.selectedEmployee = perform.employee;
      this.$refs["update-performance"].show();
      this.$refs["ph-table"].clearSelected();
    },
    submitNewPI() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.apiFormHandler(
          "Something went wrong. Inspect form and try again."
        );
      } else {
        const data = {
          employees: this.chosen_employees,
          start_date: this.start_date,
          end_date: this.end_date,
          max_end_date: this.max_end_date,
        };
        this.apiPost(
          `${this.ROUTES.performanceImprovement}/add-performance-improvement`,
          data,
          "Add New Performance Improvement error"
        ).then((res) => {
          this.apiResponseHandler(
            `${res.data}`,
            "Action successfully."
          );
          this.refreshTable();
          this.$v.$reset();
          this.$refs["add-ph"].hide();
        });
      }
    },
    updatePI() {
      this.submitted = true;
      this.$v.$touch();
      this.chosen_employees = 1;
      if (this.$v.$invalid) {
        this.apiFormHandler(
          "Something went wrong. Inspect form and try again."
        );
      } else {
        const data = {
          empId: this.empId,
          start_date: this.start_date,
          end_date: this.end_date,
          max_end_date: this.max_end_date,
          performanceId:this.performanceId,
          status:this.performanceStatus
        };
        this.apiPost(
          `${this.ROUTES.performanceImprovement}/update-performance-improvement`,
          data,
          "Update Performance Improvement error"
        ).then((res) => {
          this.apiResponseHandler(
            `${res.data}`,
            "Action successfully."
          );
          this.refreshTable();
          this.$v.$reset();
          this.$refs["update-performance"].hide();
        });
      }
    },
    deletePerformanceImprovement() {
      const url = `${this.ROUTES.performanceImprovement}/delete-performance-improvement/${this.performanceId}`;
      this.apiGet(url, "Delete Performance Improvement Error").then(async (res) => {
        this.apiResponseHandler(`${res.data}`, "Action Successful");
        this.confirmPHDelete = false;
        await this.refreshTable();
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button class="btn btn-success" @click="$refs['add-ph'].show()">
        <i class="mdi mdi-plus mr-2"></i>
        Add New
      </b-button>
    </div>
    <b-spinner type="grow" v-if="apiBusy" class="m-2" variant="success" />
    <div v-else class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="ph-table"
                bordered
                hover
                :items="performances"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
                selectable
                @row-selected="selectPerformance"
              >
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="add-ph"
      title="New Performance Improvement"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="submitNewPI">
        <b-form-group>
          <label for="">Employee(s)</label>
          <multiselect
            v-model="chosen_employees"
            :options="employees"
            :custom-label="employeeAsLabel"
            :multiple="true"
          ></multiselect>
        </b-form-group>
        <div class="form-group">
          <label for="start_date_">
            Start Date <span class="text-danger">*</span>
          </label>
          <input
            id="start_date_"
            type="date"
            max="31"
            v-model="start_date"
            v-on:blur="setStartDate"
            class="form-control"
            placeholder="Day"
            :class="{
              'is-invalid': submitted && $v.start_date.$error,
            }"
          />
        </div>
        <div class="form-group">
          <label for="end_date_">
            End Date <span class="text-danger">*</span>
          </label>
          <input
            id="end_date_"
            type="date"
            max="31"
            v-model="end_date"
            v-on:blur="setEndDate"
            class="form-control"
            placeholder="Day"
            :class="{
              'is-invalid': submitted && $v.end_date.$error,
            }"
          />
        </div>
        <b-button
          v-if="!submitting || !passed "
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>

    <b-modal
      ref="update-performance"
      title="Update Performance"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="updatePI">
        <b-form-group>
          <label for="">Employee<span class="text-danger">*</span></label>
          <input type="text" v-model="selectedEmployee" readonly class="form-control">
          <input type="hidden" v-model="chosen_employees" >
        </b-form-group>

        <b-form-group>
          <label for="">Status<span class="text-danger">*</span></label>
          <select v-model="performanceStatus" class="form-control">
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </b-form-group>

        <div class="form-group">
          <label for="public_date">
            Start Date <span class="text-danger">*</span>
          </label>
          <input
            id="start_date"
            type="date"
            max="31"
            v-model="start_date"
            class="form-control"
            v-on:blur="setStartDate"
            placeholder="Day"
            :class="{
              'is-invalid': submitted && $v.start_date.$error,
            }"
          />
        </div>
        <div class="form-group">
          <label for="public_date">
            End Date <span class="text-danger">*</span>
          </label>
          <input
            id="end_date"
            type="date"
            max="31"
            v-model="end_date"
            class="form-control"
            v-on:blur="setEndDate"
            placeholder="Day"
            :class="{
              'is-invalid': submitted && $v.end_date.$error,
            }"
          />
        </div>

        <div class="row">
          <div class="col-lg-12">
            <b-button
              v-if="!submitting"
              class="btn btn-success  mt-4"
              type="submit"
            >
              Save changes
            </b-button>
            <b-button
              v-if="!submitting"
              class="btn btn-danger  mt-4 ml-5"
              type="button"
              @click="
                $refs['update-performance'].hide();
                confirmPHDelete = true;
              "
            >
              Delete
            </b-button>
            <b-button
              v-else
              disabled
              class="btn btn-success btn-block mt-4"
              type="submit"
            >
              Processing...
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal v-model="confirmPHDelete" hide-footer hide-header centered size="sm">
      <div class="p-3">
        This action cannot be undone. Are you sure you want to <code>delete</code> this?
        <div class="row mt-3">
          <div class="col-lg-6">
            <b-button
              v-if="!submitting"
              class="btn btn-success btn-block"
              type="button"
              @click="deletePerformanceImprovement"
            >
              Confirm
            </b-button>
            <b-button
              v-else
              disabled
              class="btn btn-success btn-block"
              type="button"
            >
              Deleting...
            </b-button>
          </div>
          <div class="col-lg-6">
            <b-button
              @click="confirmPHDelete = false"
              class="btn btn-danger btn-block"
              type="button"
            >
              Cancel
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>

  </Layout>
</template>
